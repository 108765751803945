import React, { useState } from "react";
import {
  Box,
  IconButton,
  Button,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText

} from "@mui/material";

import Lottie from "lottie-react";
import DownloadIcon from "@mui/icons-material/Download";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";
import { saveAs } from "file-saver";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "@uidotdev/usehooks";
import { AsyncImage } from 'loadable-image';

import imageError from "../animations/error-anim.json";
import imageLoader from "../animations/generic-loader-anim.json";

interface ImageCellProps {
  imageUrl: string;
  onPrint: () => void;
  onSave: () => void;
}

const ErrorImage: React.FC = () => {
  return (
    <Lottie
      loop={false}
      animationData={imageError}
      style={{ margin: "auto", width: "100%", height: "100%" }} />
  );
};

const PlaceholderImage: React.FC = () => {
  return (
    <Lottie
      loop={true}
      animationData={imageLoader}
      style={{ margin: "auto", width: "100%", height: "100%" }} />
  );
};

const ImageCell: React.FC<ImageCellProps> = ({ imageUrl, onPrint, onSave }) => {
  const handleContextMenu = (event: { preventDefault: () => void }) => {
    event.preventDefault();
  };

  return (
    <Paper
      elevation={3}
      sx={{
        minWidth: 550,
        maxWidth: 550,
        height: 777,
        position: "relative",
        margin: 2,
        overflow: "hidden",
        borderRadius: "2rem"
      }}>
        
      <AsyncImage
        src={imageUrl}
        alt="Result Image"
        error={<ErrorImage />}
        loader={<PlaceholderImage />}
        onContextMenu={handleContextMenu}
        style={{width: "100%", height: "100%", objectFit: "contain" }} />

      <Box
        sx={{
          position: "absolute",
          bottom: 30,
          left: "50%",
          transform: "translateX(-50%)",
          display: "flex",
          justifyContent: "center",
          gap: 4
        }} >

        <Button
          onClick={onPrint}
          variant="contained"
          color="primary"
          sx={{ width: 50, height: 50 }}>

          Print
        </Button>

        <Button
          onClick={onSave}
          variant="contained"
          sx={{ width: 50, height: 50, minWidth: 50 }}>
            
          <DownloadIcon />
        </Button>
      </Box>
    </Paper>
  );
};

interface ResultsProps {
  images: string[];
}

const Results: React.FC<ResultsProps> = ({ images }) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const navigate = useNavigate();

  const [, setToken] = useLocalStorage("token", null);
  const [, setPrompt] = useLocalStorage("prompt", "");

  const handleDownload = (url: string) => {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const timestamp = new Date().getTime();
        saveAs(blob, `mindpaint-${timestamp}.jpg`);
      })
      .catch((e) => console.error("Error in downloading file: ", e));
  };

  const handlePrint = (url: string) => {
    const printWindow = window.open("", "_blank");

    if (printWindow) {
      printWindow.document.write(
        `<html><head><title>Print Image</title></head><body style="text-align: center;"><img src="${url}" onload="window.print();window.close();" style="max-width: 100%;"></body></html>`
      );

      printWindow.document.close();

    } else {
      alert("Please disable any popup blockers.");
    }
  };

  const handleHome = () => {
    setToken(null);
    setPrompt("");
    navigate("/");
  };

  return (
    <Box sx={{ width: "100vw", height: "100vh", overflow: "hidden" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          p: 4
        }}>

        <IconButton
          onClick={() => setOpen(true)}
          sx={{
            backgroundColor: theme.palette.primary.main,
            color: "white",
            "&:hover": { 
              backgroundColor: theme.palette.secondary.main 
            }
          }}>

          <CloseIcon />
        </IconButton>
      </Box>

      <Dialog
        open={open}
        style={{ zIndex: 9999 }}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">

        <DialogContent>
          <DialogContentText
            sx={{ margin: "10px", color: theme.palette.primary.main }}
            variant="subtitle1"
            id="alert-dialog-description">

            Are you sure you want to exit?
          </DialogContentText>

          <DialogContentText
            sx={{ color: theme.palette.primary.main }}
            variant="subtitle1"
            id="alert-dialog-description">

            You will not be able to print or save these again.
          </DialogContentText>
        </DialogContent>

        <DialogActions sx={{ justifyContent: "center" }}>
          <Button variant="contained" onClick={() => setOpen(false)}>
            Cancel
          </Button>

          <Button
            variant="contained"
            onClick={() => {
              setOpen(false);
              handleHome();
            }}
            autoFocus>

            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Box
        sx={{
          display: "flex",
          overflowX: "auto",
          p: 2,
          gap: 2,
          alignItems: "center"
        }}>

        {images.map((image, index) => (
          <ImageCell
            key={index}
            imageUrl={image}
            onPrint={() => handlePrint(image)}
            onSave={() => handleDownload(image)} />
        ))}
        
      </Box>
    </Box>
  );
};

export default Results;
