import React, { useState, useEffect } from "react";
import Lottie from "lottie-react";
import { useLocalStorage } from "@uidotdev/usehooks";
import { useAutoAnimate } from '@formkit/auto-animate/react';
import { Box, Typography } from "@mui/material";

import animation from "../animations/paint-pallet-loader.json";
import Results from "./Results";

import DreamResponse from "../utils/types/DreamResponse";
import GenericResponse from "../utils/types/GenericResponse";
import BackgroundColors from "./backgrounds/BackgroundColors";

const Paid: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [results, setResults] = useState<GenericResponse<DreamResponse>>();

  const [token] = useLocalStorage("token", null);
  const [prompt] = useLocalStorage("prompt", "");

  const [animationParent] = useAutoAnimate();

  useEffect(() => {
    (async () => {
      const results = await fetch("/api/dream", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ token, prompt }),
      });

      const json = await results.json();

      setResults(json);
      setLoading(false);
    })();

  }, [prompt, token]);

  return (
    <BackgroundColors paused={!loading}>
      <div ref={animationParent}>
      {loading ? (
        <>
          <Box sx={{ transform: "translateY(-10%)" }}>
            <Lottie
              animationData={animation}
              loop={true}
              style={{ width: "200px", height: "200px", margin: "auto" }} />

          </Box>

          <Typography variant="subtitle1">
            Fetching results, this may take several minutes...
          </Typography>
        </>

      ) : results &&
        results.data &&
        results.data.images &&
        results.data.images.length > 0 ? (

          <Results images={results?.data.images} />

      ) : (
        <Typography variant="h6" sx={{ textAlign: "center", mt: 4 }}>
          No images to display
        </Typography>
      )}
      
      </div>
    </BackgroundColors>
  );
};

export default Paid;
