import React, { useState } from "react";
import { useLocalStorage } from "@uidotdev/usehooks";
import { Box, Typography, OutlinedInput, CssBaseline } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import AnimatedBackground from "./backgrounds/AnimatedBackground";

const Home: React.FC = () => {
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);

  const [, setToken] = useLocalStorage("token", null);
  const [, setPrompt] = useLocalStorage("prompt", "");

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setInputValue(event.target.value);
  };

  const handleCheckout = async () => {
    setLoading(true);

    const data = await fetch("/api/links");
    const json = await data.json();

    setPrompt(inputValue);
    setToken(json.data.token);

    window.location.href = json.data.url;
  };

  return (
    <AnimatedBackground>
      <CssBaseline />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          paddingTop: "180px",
          width: "70%",
          margin: "auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant="h4" gutterBottom>
            {process.env.REACT_APP_NAME}
          </Typography>

          <Typography variant="subtitle1" sx={{ mb: 2 }}>
            {process.env.REACT_APP_DESCRIPTION}
          </Typography>

          <OutlinedInput
            fullWidth
            placeholder={process.env.REACT_APP_PLACEHOLDER}
            multiline
            rows={3}
            value={inputValue}
            onChange={handleInputChange}
            sx={{
              mb: 2,
              minHeight: "100px",
              backgroundColor: "white",
            }}
          />

          <LoadingButton
            variant="contained"
            loading={loading}
            onClick={handleCheckout}
            sx={{ mb: 2 }}
          >
            Create
          </LoadingButton>
        </Box>

        <Box>
          <Typography
            variant="caption"
            sx={{
              position: "absolute",
              bottom: "30px",
              left: "50%",
              transform: "translateX(-50%)",
            }}
          >
            Created by{" "}
            <a href="https://prostackdev.com" target="_blank" rel="noreferrer">
              Pro Stack
            </a>
          </Typography>
        </Box>
      </Box>
    </AnimatedBackground>
  );
};

export default Home;
