import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import Paid from "./components/Paid";

const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/paid" element={<Paid />} />
      <Route path="/" element={<Home />} />
    </Routes>
  );
};

export default AppRoutes;
